const GlobalStyle = `



:root {
  direction: rtl;
  font-family: var(--heebo);

}


`;

export default GlobalStyle;
