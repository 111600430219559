import { CarModel, HomePage } from "@/api"
import { makeVar } from "@apollo/client"

interface Inventory {
  cars: CarModel[]
  filterd: CarModel[]
}

export const homeStore = makeVar<HomePage>({
  mainBanner: {
    id: 0,
    title: "",
    alternateText: [],
    backgroundImage: {
      imageUrl: "",
      alt: "",
    },
    //@ts-ignore
    topModels: [],
  },
  topModels: [],
  advantages: {
    title: "",
    subTitle: "",
    image: { imageUrl: "", alt: "" },
    features: { features: [] },
  },
  intrestedCars: [],
  faq: {
    title: "",
    subTitle: "",
    faqs: [],
  },
  brands: [],
  showRooms: [],
})

export const inventoryStore = makeVar<Inventory>({
  cars: [],
  filterd: [],
})

export const minMaxPriceStore = makeVar<number[]>([0, 0])
export const productCarStore = makeVar<CarModel>({} as CarModel)
