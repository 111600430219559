"use client";
import { outlinedInputClasses } from "@mui/material";
import { heIL } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import GlobalStyle from "./GlobalStyle";
import colors from "./colors";

const theme = createTheme(
  {
    direction: "rtl",
    palette: {
      mode: "light",
      primary: {
        main: "#1C69D4",
      },
      secondary: {
        main: "#4D4D4D",
      },
    },
    typography: {
      fontFamily: "var(--bmw-next)",
      h1: {
        fontSize: "40px",
        fontWeight: "700",
        color: colors.black,
        textAlign: "center",
      },
      h2: {
        fontSize: 24,
        textAlign: "center",
        fontWeight: "bold",
        color: colors.black,
        letterSpacing: "0.72px",
        margin: "16px 0",

        "@media (max-width: 768px)": {
          fontSize: 18,
        },
      },
      h3: {
        "@media (max-width: 768px)": {
          fontSize: 14,
        },
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: GlobalStyle,
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: "#F2F2F2",

            "&:not(:last-child)": {
              borderBottom: "1px solid #E6E6E6",
            },
            "&:before": {
              display: "none",
            },
            "&.Mui-expanded": {
              margin: 0,
            },
            boxShadow: "none",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          rail: {
            backgroundColor: "#E6E6E6",
            opacity: 1,
          },
          valueLabel: {
            backgroundColor: "rgba(230, 230, 230, 1)",
            color: "rgba(38, 38, 38, 1)",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-focusVisible": {
              backgroundColor: "transparent",
            },
            "&.Mui-selected": {
              backgroundColor: "transparent",
            },
            "&.Mui-selected:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          outlined: {
            height: "43px",
            paddingTop: "36px",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            flexDirection: "row-reverse",
            gap: "16px",
            backgroundColor: "white",
            minHeight: "0px",
            "&.Mui-expanded": {
              backgroundColor: "#F2F2F2",
              minHeight: "0px",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: "12px 0",
              backgroundColor: "#F2F2F2",
            },
          },
          content: {
            margin: "16px 0",
            "&.Mui-expanded": {
              backgroundColor: "#F2F2F2",
              margin: "12px 0",
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: "8px 16px",
            marginRight: "16px",
            marginLeft: "16px",
          },
        },
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#262626",
            "--TextField-brandBorderHoverColor": "#262626",
            "--TextField-brandBorderFocusedColor": "#262626",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
            top: 0,
          },
          root: {
            height: "43px",
            borderRadius: "0px",
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#262626",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#262626",
            },
            "& legend": {
              display: "none",
            },
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.errorRed,
            },
          },
        },
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            transform: "translate(14px, 10px) scale(1)",
            "&.Mui-required .MuiFormLabel-asterisk": {
              display: "none",
            },
            "&.Mui-focused, &.MuiFormLabel-filled": {
              color: "#262626",
              transform: "translate(10px, -24px) scale(0.875)",
            },
            "&.Mui-focused": {
              color: "#262626",
              transform: "translate(10px, -24px) scale(0.875)",
            },
            "&.Mui-error": {
              color: colors.errorRed,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: "red",
            direction: "rtl",
            fontSize: "14px",
            position: "absolute",
            left: "0",
            top: "calc(-100% + 18px)",
            margin: 0,
            "&.Mui-error": {
              color: colors.errorRed,
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            position: "relative",
            marginBottom: "0",
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&::before, &::after": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid #262626",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid #EB0000",
            },
            "&.Mui-focused:after": {
              borderBottom: "2px solid #262626",
            },
          },
        },
      },
    },
  },
  heIL
);

export default theme;
