import theme from "@/theme/theme";
import { Box, Theme, useMediaQuery } from "@mui/material";
import type { FC } from "react";

export const useIsDesktop = () =>
  useMediaQuery<Theme>(theme.breakpoints.up("md"));

// Define the props for the component
interface MediaQueryComponentProps {
  aboveTheFold?: boolean;
  children: React.ReactNode;
}

export const Mobile: FC<MediaQueryComponentProps> = ({ children }) => (
  <Box sx={{ display: { xs: "inherit", md: "none" } }}>{children}</Box>
);
export const Desktop: FC<MediaQueryComponentProps> = ({ children }) => (
  <Box sx={{ display: { xs: "none", md: "inherit" } }}>{children}</Box>
);

export const IEMediaQuery =
  "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)";

export const EdgeMediaQuery = "@supports (-ms-ime-align:auto)";
