import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const InfoTagWrapper = styled.div`
  display: flex;
  background-color: rgba(28, 105, 212, 0.1);
  border-radius: 32px 32px 32px 32px;
  padding: 2px 8px 2px 8px;
  height: 21px;
  width: fit-content;
  margin-top: 10px;
  gap: 10px;
`;
interface InfoTagProps {
  info: string;
}

export const InfoTag = ({ info }: InfoTagProps) => {
  return (
    <InfoTagWrapper>
      <Typography fontSize={12} fontWeight={300}>
        {info}
      </Typography>
    </InfoTagWrapper>
  );
};
