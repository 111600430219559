/** THIS FILE IS AUTOMATICALLY GENERATED FROM : https://preprod-api-bmwpremiumselection.bmw.co.il/swagger/v1/swagger.json **/

import axios from "../axios"
import * as BMW from "./BMWInterfaces"

export const getAccountGenerateVerificationCode = (
  mobilePhone: string,
  toSendSms: boolean = true,
) =>
  axios.get<BMW.GenerateVerificationCodeResponseResponseWrapper>(
    `/api/account/generateVerificationCode`,
    { params: { mobilePhone, toSendSms }, headers: {} },
  )

export const getAccountUpdateLastSeen = () =>
  axios.get<BMW.DateTimeResponseWrapper>(`/api/account/updateLastSeen`, {
    headers: {},
  })

export const getAccountGetUserInfo = () =>
  axios.get<BMW.UserInfoResponseResponseWrapper>(`/api/account/getUserInfo`, {
    headers: {},
  })

export const postAccountRegister = (data: BMW.RegistrationRequest) =>
  axios.post<BMW.RegistrationResponseResponseWrapper>(
    `/api/account/register`,
    data,
    { headers: {} },
  )

export const postAccountFacebookConnect = (
  data: BMW.ThirdPartyConnectRequest,
) =>
  axios.post<BMW.ThirdPartyConnectResponseResponseWrapper>(
    `/api/account/facebookConnect`,
    data,
    { headers: {} },
  )

export const postAccountAppleConnect = (data: BMW.ThirdPartyConnectRequest) =>
  axios.post<BMW.ThirdPartyConnectResponseResponseWrapper>(
    `/api/account/appleConnect`,
    data,
    { headers: {} },
  )

export const postAccountVerifyUser = (data: BMW.VerifyUserRequest) =>
  axios.post<BMW.UserInfoResponseResponseWrapper>(
    `/api/account/verifyUser`,
    data,
    { headers: {} },
  )

export const postAccountLogout = () =>
  axios.post<BMW.BooleanResponseWrapper>(`/api/account/logout`, { headers: {} })

export const getAppMetaDataGetAllBrands = () =>
  axios.get<BMW.BrandDtoListResponseWrapper>(`/api/appMetaData/getAllBrands`, {
    headers: {},
  })

export const getAppMetaDataGetAllShowRoom = () =>
  axios.get<BMW.ShowRoomDtoListResponseWrapper>(
    `/api/appMetaData/getAllShowRoom`,
    { headers: {} },
  )

export const getAppMetaDataGetFaq = () =>
  axios.get<BMW.FaqDetailsDtoResponseWrapper>(`/api/appMetaData/getFaq`, {
    headers: {},
  })

export const getAppMetaDataGetAllAdvantages = () =>
  axios.get<BMW.AdvantageDtoResponseWrapper>(
    `/api/appMetaData/getAllAdvantages`,
    { headers: {} },
  )

export const getAppMetaDataGetMainBanner = () =>
  axios.get<BMW.MainBannerDtoResponseWrapper>(
    `/api/appMetaData/getMainBanner`,
    { headers: {} },
  )

export const getAppMetaDataGetInventoryInfo = () =>
  axios.get<BMW.InventoryDtoResponseWrapper>(
    `/api/appMetaData/getInventoryInfo`,
    { headers: {} },
  )

export const getCarmodelGetAll = () =>
  axios.get<BMW.CarModelDtoListResponseWrapper>(`/api/carmodel/getAll`, {
    headers: {},
  })

export const getCarmodelGetById = (id: number /* int32 */) =>
  axios.get<BMW.CarModelDtoResponseWrapper>(`/api/carmodel/getById`, {
    params: { id },
    headers: {},
  })

export const postCarmodelInrestedCars = (data: BMW.IntrestedCarRequest) =>
  axios.post<BMW.CarModelDtoListResponseWrapper>(
    `/api/carmodel/InrestedCars`,
    data,
    { headers: {} },
  )

export const getClearcacheClearAll = () =>
  axios.get<BMW.BooleanResponseWrapper>(`/api/clearcache/clearAll`, {
    headers: {},
  })

export const postContactContact = (data: BMW.Contact) =>
  axios.post<BMW.BooleanResponseWrapper>(`/api/contact/Contact`, data, {
    headers: {},
  })

export const getExampleRedisTest = () =>
  axios.get<BMW.MessageReponseListResponseWrapper>(`/api/example/redisTest`, {
    headers: {},
  })

export const getSettingsAll = () =>
  axios.get<BMW.GeneralSettingResponseListResponseWrapper>(
    `/api/settings/all`,
    { headers: {} },
  )

export const getHomepageHomePage = () =>
  axios.get<BMW.HomePageDtoResponseWrapper>(`/api/homepage/HomePage`, {
    headers: {},
  })

export const getMessagesMarkAsRead = (messageId: number /* int32 */) =>
  axios.get<BMW.BooleanResponseWrapper>(`/api/messages/markAsRead`, {
    params: { messageId },
    headers: {},
  })

export const getMessagesMarkAsReadByNotification = (
  notificationId: number /* int32 */,
) =>
  axios.get<BMW.BooleanResponseWrapper>(
    `/api/messages/markAsReadByNotification`,
    { params: { notificationId }, headers: {} },
  )

export const postMessagesGet = (FilterType: string) =>
  axios.post<BMW.MessageReponseListResponseWrapper>(`/api/messages/get`, {
    params: { FilterType },
    headers: {},
  })

export const getSearchGetAllBrandPrices = () =>
  axios.get<BMW.BrandsPriceDtoResponseWrapper>(
    `/api/search/getAllBrandPrices`,
    { headers: {} },
  )

export const postSearchSearchCars = (data: BMW.SearchRequest) =>
  axios.post<BMW.SearchResponseResponseWrapper>(
    `/api/search/searchCars`,
    data,
    { headers: {} },
  )

export const postSearchGetPriceRangeByBrands = (data: number /* int32 */[]) =>
  axios.post<BMW.PriceRangeResponseResponseWrapper>(
    `/api/search/GetPriceRangeByBrands`,
    data,
    { headers: {} },
  )

export const getSeoKey = (key: string) =>
  axios.get<BMW.SeoMetaDataDtoResponseWrapper>(`/api/seo/${key}`, {
    headers: {},
  })
