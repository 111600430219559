export const transltePrices = (price: string): string => {
  return price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function formatLabel(value: number) {
  if (value >= 1000000) {
    return `${(value / 1000000).toLocaleString()} מיליון`
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(0)} אלף`
  }
  return `${value}₪`
}
export function formatLabelString(value: number) {
  return `₪${value.toLocaleString()}`
}
export function stepCalculation(value: number) {
  if (value >= 1000000) {
    return 1000000
  } else if (value >= 1000) {
    return 1000
  }
  return 1
}
